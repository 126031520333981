/* global window, sessionStorage */

import { createRouter, createWebHashHistory } from 'vue-router';
import logger from '@/scripts/logger';
import { jwtDecode } from 'jwt-decode';

/*
import pageCallback from '@/callback.vue';
import showInvoices from '@/views/invoices/show.vue';
import pageRegister from '@/views/register/page-register.vue';
import pageOrganization from '@/views/register/page-organization.vue';
import pageLogin from '@/views/login/page-login.vue';
import pagePasswordReset from '@/views/login/page-password-reset.vue';
import pageMfa from '@/views/login/page-mfa.vue';
import pageCases from '@/views/workspaces/cases/page-cases.vue';
import pageCase from '@/views/workspaces/case/page-case.vue';
import pageTranscript from '@/views/workspaces/transcript/page-transcript.vue';
import userSettings from '@/views/settings/user.vue';
import orgSettings from '@/views/settings/org.vue';
*/
const home = { template: '<div></div>' };

const routes = [
    {
        path: '/',
        beforeEnter() {
            window.location.href = 'https://goskript.com';
        },
        component: home,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/help',
        beforeEnter() {
            window.location.href = import.meta.env.VITE_HELP_URL;
        },
        component: home,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/help/ticket',
        beforeEnter() {
            window.location.href = import.meta.env.VITE_HELP_TICKET_URL;
        },
        component: home,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/callback',
        component: () => import(/* webpackChunkName: "core" */ '@/callback.vue'),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/invoices/show',
        component: () => import(/* webpackChunkName: "invoices" */ '@/views/invoices/show.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/register',
        component: () => import(/* webpackChunkName: "register" */ '@/views/register/page-register.vue'),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/register/organization',
        component: () => import(/* webpackChunkName: "register" */ '@/views/register/page-organization.vue'),
    },
    {
        path: '/login',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/page-login.vue'),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/password/reset',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/page-password-reset.vue'),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/mfa/enroll',
        component: () => import(/* webpackChunkName: "login" */ '@/views/login/page-mfa.vue'),
    },
    {
        path: '/section/cases',
        component: () => import(/* webpackChunkName: "cases" */ '@/views/workspaces/cases/page-cases.vue'),
    },
    {
        path: '/section/case',
        component: () => import(/* webpackChunkName: "cases" */ '@/views/workspaces/case/page-case.vue'),
    },
    {
        path: '/section/transcript',
        component: () => import(/* webpackChunkName: "transcript" */ '@/views/workspaces/transcript/page-transcript.vue'),
    },
    {
        path: '/viewer/transcript',
        component: () => import(/* webpackChunkName: "transcript" */ '@/views/workspaces/transcript/page-transcript.vue'),
        meta: {
            allowViewer: true,
        },
    },
    {
        path: '/user/settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/user.vue'),
    },
    {
        path: '/org/settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/org.vue'),
        meta: {
            requiresRole: 'org:admin',
        },
    },
];

if (process.env.NODE_ENV === 'development') {
    routes.push({
        path: '/admin/home',
        component: () => import(/* webpackChunkName: "login" */ '@/views/admin/page-admin.vue'),
        meta: {
            requiresAuth: true,
            requiresRole: 'system:admin',
        },
    });
}

const router = createRouter({
    history: createWebHashHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((record) => {
        return record.meta?.['requiresAuth'] === undefined ? true : record.meta['requiresAuth'];
    });

    sessionStorage.setItem('admin', to.fullPath.startsWith('/admin/'));

    const requiresRole = to.meta?.['requiresRole'];
    const allowViewer = to.meta?.['allowViewer'];

    logger.debug('Router - authRequired', authRequired, 'role', requiresRole ?? '(all)');

    if (!authRequired) {
        return next();
    }

    try {
        const authToken = sessionStorage.getItem('authToken');
        const decodedToken = authToken ? jwtDecode(authToken) : null;
        const currentRoles = [...(decodedToken?.['roles'] ?? '').split(','), sessionStorage.getItem('role')].filter(
            (e) => (e ?? '').length > 0,
        );

        if (requiresRole) {
            if (!currentRoles.includes(requiresRole)) {
                return next('/section/cases');
            }
        }

        if (!allowViewer) {
            if (decodedToken?.['scope'] === 'view') next('/');
        }

        // trying to access a restricted page + not logged in
        // redirect to login page
        if (!authToken) {
            next('/login');
        } else {
            next();
        }
    } catch (e) {
        logger.error(e);
    }
});

export default router;
