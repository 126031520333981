import { Tooltip } from 'bootstrap';

let f;

const tooltip = {
    mounted(el) {
        let tt = new Tooltip(el);
        f = () => {
            if (tt) {
                tt.hide();
            }
        };
        el.addEventListener('mousedown', f);
    },
    beforeUnmount(el) {
        el.removeEventListener('mousedown', f);
    },
    updated(el) {
        let tt = new Tooltip(el);
        f = () => {
            if (tt) {
                tt.hide();
            }
        };
    },
};

export default tooltip;
