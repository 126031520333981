<template>
    <div v-if="!isBrowserSupported" class="row justify-content-md-center">
        <div class="mt-4 alert alert-danger alert-dismissible col-4" role="alert">
            <button type="button" class="btn-close" data-bs-dismiss="alert" />
            <div class="pt-2 fw-bold">
                <i class="bi bi-exclamation-triangle-fill me-2" />
                GoSkript has been designed to run on the latest web browser standards.
            </div>
            <div class="mt-4 mb-2">Please consider upgrading your browser to the latest version before proceeding.</div>
        </div>
    </div>

    <router-view />
</template>

<script setup>
import { onMounted, onBeforeUnmount, onBeforeMount, computed } from 'vue';
import logger from '@/scripts/logger';

import { useUserStore } from '@/stores/userStore.js';
const userStore = useUserStore();

import { useSettingsStore } from '@/stores/settingsStore.js';

const userSettings = useSettingsStore();

import { useEventListener } from '@vueuse/core';

const isBrowserSupported = computed(() => {
    return true; //(new BrowserDetector).isSupported();
});

window.onerror = function (msg, url, line, col, error) {
    logger.error(msg, url, line, col, error);
};

let intervalId;

function sessionTrack() {
    sessionStorage.setItem('_lastActivity', Date.now());
}

let fileStore;

intervalId = setInterval(() => {
    try {
        if (!userStore.loggedIn) {
            return;
        }

        if (fileStore.activeUploadTasks.length > 0) {
            logger.debug('Active upload tasks, setting lastActivity');
            sessionTrack();
        }

        let lastActivity = sessionStorage.getItem('_lastActivity');

        // You are logged in but there is no last activity key set, set one up.
        if (!lastActivity) {
            sessionTrack();
            return;
        }

        let activityDelta = Date.now() - parseInt(lastActivity);

        let _timeout = userSettings.automaticLogout;

        if (_timeout > 0 && activityDelta > _timeout * 60 * 1000) {
            // Logout and go back to the
            userStore
                .logout()
                .then(() => (window.location = 'https://goskript.com'))
                .catch();
        } else {
            // Refresh the token
            userStore.refresh();
        }
    } catch (err) {
        logger.error(err);
    }
}, 30 * 1000);

useEventListener(window, 'beforeunload', (e) => {
    if (fileStore.activeUploadTasks.length > 0 || fileStore.failedUploadTasks.length > 0) {
        let confirmationMessage =
            'Wait! You are currently uploading content. \n' +
            "If you leave now these uploads will be cancelled. Are you sure you'd like to continue?";

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
});

onBeforeUnmount(async () => {
    /*
    if ((fileStore?.uploadTasks ?? []).length > 0){
        await fileStore.cancel();
    }
*/
    logger.debug('clearing events listeners for inactivity timeouts');

    clearInterval(intervalId);

    // remove handlers
    window.removeEventListener('scroll', sessionTrack);
    window.removeEventListener('mousedown', sessionTrack);
    window.removeEventListener('keydown', sessionTrack);

    sessionStorage.removeItem('_lastActivity');
});

onBeforeMount(() => {
    logger.debug('adding events listeners for inactivity timeouts');

    //  window.addEventListener('mousemove', sessionTrack );
    window.addEventListener('scroll', sessionTrack);
    window.addEventListener('mousedown', sessionTrack);
    window.addEventListener('keydown', sessionTrack);
});

onMounted(() => {
    if (process.env.NODE_ENV !== 'development' && import.meta.env.VITE_ENVIRONMENT !== 'trial') {
        let externalScript = document.createElement('script');
        externalScript.setAttribute('src', `./newrelic-${import.meta.env.VITE_ENVIRONMENT}.js`);
        document.head.appendChild(externalScript);
    }

    // Delay loading the fileStore as we need the app to be loaded with pinia before we can instantiate
    import('@/stores/fileStore.js').then((module) => {
        fileStore = module.useFileStore();
    });
});
</script>

<style lang="scss">
@import 'app.scss';
</style>
