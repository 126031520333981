import { createApp } from 'vue';
import { createPinia } from 'pinia';
const pinia = createPinia();

import App from '@/app.vue';

import router from '@/scripts/router';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';

import tooltip from '@/components/tooltip';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';

/* add icons to the library */
library.add(fab);

const app = createApp(App).use(pinia).use(router);

app.directive('tooltip', tooltip);

app.component('FontAwesomeIcon', FontAwesomeIcon);

router.app = app;

router.isReady().then(() => app.mount('#app'));
